import { Box, IconButton, Typography } from "@mui/material";
import { getReasonsNameByCode } from "../../utils/CommonUtils";
import { formatDateBasedOnLocale } from "../../utils/DateUtils";
import { CopyAllRounded } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";

const ACTTransactionsMultipleAllList = ({
  failedReasons,
  onCopyData,
  moreItems,
  latLngGroup,
}) => { 

  const theme = useTheme();
  const customtextColorForDarkmode = (theme) => ({
    color: theme.palette.mode === "dark" && "#ffffff",
  });

  return (
    <>
      {moreItems && moreItems.map((result) => {
        return (
          <Box 
            sx={{
              padding: "0.5rem 0.25rem 0.5rem 0.5rem",
              '&:nth-of-type(odd)': {
                backgroundColor: "none",
              },
              '&:nth-of-type(even)': {
                backgroundColor: theme.palette.mode === "light" && "#ececec",
                borderTop: "0.1rem solid #ececec",
                borderBottom: "0.1rem solid #ececec",
              },
            }}
            className="cluster-data-container"
            style={customtextColorForDarkmode(theme)}
          >
            <div className="cluster-data-panel">
              <Typography
                sx={{
                  fontSize: "0.8125rem",
                  lineHeight: "1",
                }}
                style={customtextColorForDarkmode(theme)}
              >
                <strong>Date & Time:</strong>{" "}
                {formatDateBasedOnLocale(result.Timestamp)}
              </Typography>
            </div>

            <div className="cluster-data-panel copy">
              <Typography
                sx={{
                  fontSize: "0.8125rem",
                  lineHeight: "1",
                }}
                style={customtextColorForDarkmode(theme)}
              >
                <strong>Device ID:</strong> {result.DeviceId}
              </Typography>
              <div>
                <IconButton
                  aria-label="copy"
                  size="small"
                  onClick={() => onCopyData(result.DeviceId)}
                >
                  <CopyAllRounded
                    className="copy-icon"
                    style={customtextColorForDarkmode(theme)}
                  />
                </IconButton>
              </div>
            </div>

            <div className="cluster-data-panel copy">
            <Typography
                sx={{
                  fontSize: "0.8125rem",
                  lineHeight: "1",
                }}
                style={customtextColorForDarkmode(theme)}
              >
                <strong>Account ID:</strong> {result.AccountId}
              </Typography>
              <div>
                <IconButton
                  aria-label="copy"
                  size="small"
                  onClick={() => onCopyData(result.AccountId)}
                >
                  <CopyAllRounded
                    className="copy-icon"
                    style={customtextColorForDarkmode(theme)}
                  />
                </IconButton>
              </div>
            </div>

            {/* <div className="cluster-data-panel copy">
            <Typography
                sx={{
                  fontSize: "0.8125rem",
                  lineHeight: "1",
                }}
                style={customtextColorForDarkmode(theme)}
              >
                <strong>Transaction ID:</strong> {result.GeoReferenceId}
              </Typography>
              <div>
                <IconButton
                  aria-label="copy"
                  size="small"
                  onClick={() => onCopyData(result.GeoReferenceId)}
                >
                  <CopyAllRounded
                    className="copy-icon"
                    style={customtextColorForDarkmode(theme)}
                  />
                </IconButton>
              </div>
            </div> */}

            <div className="cluster-data-panel">
            <Typography
                sx={{
                  fontSize: "0.8125rem",
                  lineHeight: "1",
                }}
                style={customtextColorForDarkmode(theme)}
              >
                <strong>Status: </strong>{" "}
                {getReasonsNameByCode(result.ReasonForFailure, failedReasons)}{" "}
              </Typography>
              <br></br>
            </div>
            <div className="cluster-data-panel copy">
            <Link
              to={"/summary/device/" + result.DeviceId}
              state={{ type: "device", id: result.DeviceId }}
            >
              {"view all device activity"}
            </Link>
          </div>
          </Box>
        );
      })}
    </>
  );
};

export default ACTTransactionsMultipleAllList;
