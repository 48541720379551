import React, { useContext, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Box, Grid, TextField } from "@mui/material";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { styled } from "@mui/system";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import InputAdornment from "@mui/material/InputAdornment";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { formatDateYYYYMMDD } from "../../utils/DateUtils";

import "dayjs/locale/en-gb";
import "dayjs/locale/en";
import ACTDropdownList from "../shared/ACTDropdownList";
import { AppContext } from "../../context/AppContext";
import ACTDropdownWithCollapseMenuItemList from "../shared/ACTDropdownWithCollapseMenuItemList";

var userLocale = Intl.DateTimeFormat().resolvedOptions().locale;
if (!userLocale) {
  userLocale = navigator.language || navigator.userLanguage;
}

const CustomInput = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    paddingRight: 0,
  },
  "& .MuiInputAdornment-root": {
    marginLeft: theme.spacing(1),
  },
}));

const DashboardHeaderMobile = (props) => {
  const {
    onTabSelect,
    onRefreshData,
    type,
    reasonsForFail,
    userApps,
    totalRecords,
  } = props;
  const [value, setValue] = useState(0);
  const [selectedDate, setSelectedDate] = React.useState(null);
  const { dashboardStates, setDashboardData } = useContext(AppContext);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (onTabSelect) {
      onTabSelect(newValue);
    }
  };

  const handleDateChange = (newDate) => {
    if (newDate) {
      const formattedDate = formatDateYYYYMMDD(newDate);
      if (onRefreshData) {
        onRefreshData(formattedDate, type);
      }
    }
  };

  const refreshData = (newValue, type, key) => {
    if (onRefreshData) {
      onRefreshData(newValue, type);
      setDashboardData(key, newValue);
    }
  };

  return (
    <Grid
      sx={{ flexGrow: 1, justifyContent: "center" }}
      container
      spacing={2}
      style={{
        paddingTop: "1rem",
        paddingBottom: "1rem",
      }}
    >
      <Grid item xs={6} sm={4}>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={userLocale.toLowerCase()}
        >
          <MobileDatePicker
            format={
              userLocale.toLowerCase() === "en-gb" ? "DD/MM/YYYY" : "MM/DD/YYYY"
            }
            defaultValue={dayjs()}
            sx={{
              "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
            InputProps={{
              sx: {
                "& .MuiPickersYear-yearButton": { textAlign: "center" },
              },
            }}
            slotProps={{
              textField: {
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <KeyboardArrowDownIcon />
                    </InputAdornment>
                  ),
                  sx: {
                    textAlign: "right",
                    ".MuiInputBase-input": {
                      textAlign: "right",
                      margin: ".5rem",
                    },
                  },
                },
              },
            }}
            onChange={handleDateChange}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={6} sm={4}>
        {userApps && (
          <ACTDropdownList
            name={"user_apps"}
            options={userApps}
            title={"Select User Apps"}
            type={2}
            onRefreshData={refreshData}
            selectedItem={dashboardStates["user_apps"] || null}
          />
        )}
      </Grid>
      <Grid item>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {reasonsForFail && (
            // <ACTDropdownList
            //   name={"failed_reasons"}
            //   options={reasonsForFail}
            //   title={"Select Reason for Fail"}
            //   type={5}
            //   onRefreshData={refreshData}
            //   selectedItem={dashboardStates["failed_reasons"] || null}
            // />
            <ACTDropdownWithCollapseMenuItemList
              name={"failed_reasons"}
              options={reasonsForFail}
              title={"Select Reason for Fail"}
              type={5}
              onRefreshData={refreshData}
              selectedItem={dashboardStates["failed_reasons"] || null}
            />
          )}
          ({totalRecords})
        </div>
      </Grid>
    </Grid>
  );

  // return (
  //   <Box sx={{ display: "flex", flexDirection: "row" }}>
  //     <Tabs
  //       value={value}
  //       onChange={handleChange}
  //       textColor="#000000"
  //       TabIndicatorProps={{
  //         style: {
  //           backgroundColor: "#000000",
  //         },
  //       }}
  //     >
  //       <Tab
  //         label="Map"
  //         sx={{
  //           fontWeight: value === 0 ? "bold" : "medium",
  //           color: value === 0 ? "black" : "gray",
  //         }}
  //       />
  //       <Tab
  //         label="Transactions"
  //         sx={{
  //           fontWeight: value === 1 ? "bold" : "medium",
  //           color: value === 1 ? "black" : "gray",
  //         }}
  //       />
  //     </Tabs>
  //     <div
  //       style={{
  //         display: "flex",
  //         alignItems: "center",
  //         justifyContent: "center",
  //       }}
  //     >
  //       <LocalizationProvider
  //         dateAdapter={AdapterDayjs}
  //         adapterLocale={userLocale.toLowerCase()}
  //       >
  //         <MobileDatePicker
  //           format={
  //             userLocale.toLowerCase() === "en-gb" ? "DD/MM/YYYY" : "MM/DD/YYYY"
  //           }
  //           defaultValue={dayjs()}
  //           sx={{
  //             "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
  //               border: "none",
  //             },
  //           }}
  //           InputProps={{
  //             sx: {
  //               "& .MuiPickersYear-yearButton": { textAlign: "center" },
  //             },
  //           }}
  //           slotProps={{
  //             textField: {
  //               InputProps: {
  //                 endAdornment: (
  //                   <InputAdornment position="end">
  //                     <KeyboardArrowDownIcon />
  //                   </InputAdornment>
  //                 ),
  //                 sx: {
  //                   textAlign: "right",
  //                   ".MuiInputBase-input": {
  //                     textAlign: "right",
  //                   },
  //                 },
  //               },
  //             },
  //           }}
  //           onChange={handleDateChange}
  //         />
  //       </LocalizationProvider>
  //     </div>
  //   </Box>
  // );
};

export default DashboardHeaderMobile;
