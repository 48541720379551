import React, { useCallback } from "react";
import {
  AdvancedMarker,
  useAdvancedMarkerRef,
  Pin,
} from "@vis.gl/react-google-maps";

const FeatureMarker = (props) => {
  const { position, onMarkerClick, item, bgColor } = props;
  const [markerRef, marker] = useAdvancedMarkerRef();
  const handleClick = useCallback(
    () => onMarkerClick && onMarkerClick(marker, item),
    [onMarkerClick, marker, item]
  );

  return (
    <AdvancedMarker
      key={item?.GeoReferenceId}
      ref={markerRef}
      position={position}
      onClick={handleClick}
    >
      <Pin
        background={bgColor}
        glyphColor={"#ffffff"}
        borderColor={"#ffffff"}
      />
    </AdvancedMarker>
  );
};

export default FeatureMarker;
