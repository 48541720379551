import React from "react";

import "./GenericErrorStyles.scss";
import { Box } from "@mui/material";
import { useTheme } from "@emotion/react";

const contactSupport = () => {
  console.log("contact support");
};

const SearchNoData = (props) => {
  const { data } = props;
  const theme = useTheme();
  
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#ffffff",
        height: "70vh",
        width: "100%",
        backgroundColor: theme.palette.mode === 'dark' ? '#1c1c1c' : '#d8d8d8',
      }}
    >
      <img src={data.image} alt="No data found" className="no-data-image" />
      <p className="no-data-text" style={{ color: theme.palette.text.primary }}>{data.title}</p>
      <p className="no-data-text small" style={{ color: theme.palette.text.primary }}>
      If the issue persists please<br/>
        <button onClick={contactSupport} className="contact-button">
          contact support
        </button>
      </p>
    </Box>
  );
};

export default SearchNoData;
