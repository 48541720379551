import React, { useState } from "react";
import { styled, alpha, useMediaQuery, borderBottom, Box } from "@mui/system";
import MenuItem from "@mui/material/MenuItem";
import { Button, Menu } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { groupReasonForLookups } from "../../utils/CommonUtils";

import { LOOKUP_MENUS } from "../../mock/menumock";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import CircleIcon from "@mui/icons-material/Circle";

// Define a custom MenuItem component
const CustomMenuItem = styled(MenuItem)(({ theme, isSelected }) => ({
  position: "relative",
  backgroundColor: "transparent", // Default background
  // backgroundColor: isSelected ? theme.palette.grey[300] : 'transparent',
  "&:hover": {
    backgroundColor: theme.palette.mode === "light" && theme.palette.grey[200],
  },
  "&:focus": {
    backgroundColor: theme.palette.mode === "light" && theme.palette.grey[300],
    "&:hover": {
      backgroundColor:
        theme.palette.mode === "light" && theme.palette.grey[200],
    },
  },
  "&:focusVisible, &:focusWithin, &:active": {
    backgroundColor: theme.palette.mode === "light" && theme.palette.grey[200], // Custom color when clicked
  },
  // Ensures no default styling is applied during click
  "&.Mui-focusVisible, &.Mui-selected, &.Mui-focusVisible.Mui-selected": {
    backgroundColor: theme.palette.mode === "light" && theme.palette.grey[300],
    outline: "none",
    boxShadow: "none",
  },

  // Disable transition effects that might cause delay
  transition: "background-color 0s",
  // Add a divider-like border after the last child using a pseudo-element
  "&::after": {
    content: '""',
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "100%",
    height: "1px",

    backgroundColor: theme.palette.divider,
  },
}));

const StyledMenu = styled((props) => {
  const { selectedItems } = props;
  return (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  );
})(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    width: 260,

    color: "#000000",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "0.7rem 0",
    },
    "& .MuiMenuItem-root": {
      borderBottom: "0.08rem solid #d1d1d1",
      "& .MuiSvgIcon-root": {
        fontSize: 20,
        color: theme.palette.text.secondary,
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
    ...theme.applyStyles("dark", {
      color: theme.palette.grey[300],
    }),
  },
}));

const ACTDropdownWithCollapseMenuItemList = (props) => {
  const { options, title, onRefreshData, type, selectedItem, name } = props;
  const theme = useTheme();
  var found = LOOKUP_MENUS.find((item) => item.code === selectedItem?.Code);
  if (!found && selectedItem) {
    found = {
      title: selectedItem.FriendlyName,
      code: selectedItem.Code,
      items: [],
      selected: true,
    };
  }
  const [menuItems, setMenuItems] = useState(LOOKUP_MENUS);
  const [subMenuSelectedItems, setSubMenuSelectedItems] = useState(null);

  const [selectedItems, setSelectedItems] = useState(
    found ? found : LOOKUP_MENUS[0]
  );

  // const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [selectedInnerMenu, setSelectedInnerMenu] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRootMenuClose = (menuItem) => {
    if (onRefreshData) {
      const selectedItem = options.find((item) => item.id === menuItem.code);
      onRefreshData(selectedItem, type, name);
    }
    setAnchorEl(null);
  };

  const handleMenuClick = (item) => {
    const menulist = menuItems.map((menu) => {
      if (menu.code === item.code) {
        return { ...menu, selected: !item?.selected };
      } else {
        return { ...menu, selected: false };
      }
    });
    setMenuItems(menulist);
    if (
      item.code === 101 ||
      item.code === 102 ||
      item.code === 103 ||
      item.code === 104 ||
      item.code === 105 ||
      item.code === -1
    ) {
    } else {
      if (onRefreshData) {
        const selectedItem = options.find(
          (menuitem) => menuitem.id === item.code
        );
        onRefreshData(selectedItem, type, name);
      }
      setSelectedItems(item);
    }
  };

  const handleSubMenuClick = (item) => {
    if (item.title === subMenuSelectedItems?.title) {
      const submenu = { ...item, selected: !subMenuSelectedItems?.selected };
      setSubMenuSelectedItems(submenu);
    } else {
      const submenu = { ...item, selected: true };
      setSubMenuSelectedItems(submenu);
    }

    if (
      item.code === 101 ||
      item.code === 102 ||
      item.code === 103 ||
      item.code === 104 ||
      item.code === 105 ||
      item.code === -1
    ) {
    } else {
      if (onRefreshData) {
        const selectedItem = options.find(
          (menuitem) => menuitem.id === item.code
        );
        onRefreshData(selectedItem, type, name);
      }
      setSelectedItems(item);
    }
  };

  const handleInnerMenuClick = (menuItem) => {
    if (onRefreshData) {
      const selectedItem = options.find((item) => item.id === menuItem.code);
      onRefreshData(selectedItem, type, name);
    }
    setSelectedItems(menuItem);
    setAnchorEl(null);
    setSelectedInnerMenu(menuItem);
  };

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="text"
        disableElevation
        disableRipple
        onClick={handleClick}
        sx={{
          margin: "1rem",
          width: "250px",
          display: "flex",
          justifyContent: "space-between",
          fontWeight: "400",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          color: theme.palette.text.primary,
          "&:hover": {
            backgroundColor: "transparent",
          },
          boxShadow: "#d1d1d1",
        }}
        endIcon={
          anchorEl ? (
            <ArrowDropUpIcon
              sx={{
                color: "#666666",
                height: "1.5rem",
              }}
            />
          ) : (
            <ArrowDropDownIcon
              sx={{
                color: "#666666",
                height: "1.5rem",
              }}
            />
          )
        }
      >
        {selectedItems ? selectedItems.title : "Select Checks"}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {menuItems.flatMap((item, index) => {
          const isSelected = item.code === selectedItems?.code;
          if (item.items.length === 1) {
            return (
              <MenuItem
                key={index}
                onClick={handleClose}
                disableRipple
                sx={{
                  backgroundColor: isSelected && theme.palette.grey[300],
                  "&:hover": {
                    backgroundColor: theme.palette.grey[200],
                  },
                }}
              >
                {item.title}
              </MenuItem>
            );
          } else {
            return [
              <MenuItem
                style={{ display: "flex", justifyContent: "space-between" }}
                key={`${index}-main`}
                onClick={() => {
                  handleMenuClick(item);
                  if (!item.items.length) handleClose();
                }}
                disableRipple
                sx={{
                  backgroundColor: isSelected && theme.palette.grey[300],
                  "&:hover": {
                    backgroundColor: theme.palette.grey[200],
                  },
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {item.title}
                {item.items.length > 0 ? (
                  item?.selected ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  )
                ) : null}
              </MenuItem>,

              item.selected &&
                item.items.map((subMenu, subindex) => {
                  const isSubMenuSelected =
                    subMenu.code === subMenuSelectedItems?.code;
                  if (subMenu.items.length === 1) {
                    return (
                      <MenuItem
                        style={{ marginLeft: "1rem" }}
                        key={`${index}-${subindex}`}
                        onClick={() => {
                          handleSubMenuClick(subMenu);
                          handleClose();
                        }}
                        disableRipple
                        sx={{
                          marginLeft: "1rem",
                          backgroundColor:
                            isSubMenuSelected && theme.palette.grey[300],
                          "&:hover": {
                            backgroundColor: theme.palette.grey[200],
                          },
                        }}
                      >
                        {subMenu.title}
                      </MenuItem>
                    );
                  } else {
                    return [
                      <MenuItem
                        style={{
                          paddingLeft: "1.2rem",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        key={`${index}-${subindex}-sub`}
                        onClick={() => {
                          handleSubMenuClick(subMenu);
                          if (!subMenu.items.length) handleClose();
                        }}
                        disableRipple
                        sx={{
                          backgroundColor:
                            isSubMenuSelected && theme.palette.grey[300],
                          "&:hover": {
                            backgroundColor: theme.palette.grey[200],
                          },
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            backgroundColor:
                              isSubMenuSelected && theme.palette.grey[300],
                            "&:hover": {
                              backgroundColor: theme.palette.grey[200],
                            },
                          }}
                        >
                          <CircleIcon
                            sx={{
                              height: "0.5rem",
                            }}
                          />
                          {subMenu.title}
                        </Box>
                        {subMenu.items.length > 0 ? (
                          subMenuSelectedItems?.title === subMenu.title &&
                          subMenuSelectedItems?.selected ? (
                            <ArrowDropUpIcon />
                          ) : (
                            <ArrowDropDownIcon />
                          )
                        ) : null}
                      </MenuItem>,

                      subMenuSelectedItems?.title === subMenu.title &&
                        subMenuSelectedItems?.selected &&
                        subMenu.items.map((innerMenu, innerindex) => (
                          <MenuItem
                            key={`${index}-${subindex}-${innerindex}-inner`}
                            style={{
                              paddingLeft: "2rem",
                              backgroundColor:
                                selectedInnerMenu?.code === innerMenu.code &&
                                theme.palette.grey[300],
                            }}
                            onClick={() => handleInnerMenuClick(innerMenu)}
                            disableRipple
                          >
                            <CircleIcon
                              sx={{
                                height: "0.5rem",
                                marginRight: "-0.65rem",
                              }}
                            />
                            <CircleIcon
                              sx={{
                                height: "0.5rem",
                              }}
                            />
                            {innerMenu.title}
                          </MenuItem>
                        )),
                    ];
                  }
                }),
            ];
          }
        })}
      </StyledMenu>
    </div>
  );
};

export default ACTDropdownWithCollapseMenuItemList;
