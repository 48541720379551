import React, { useState } from "react";
import { styled } from "@mui/system";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {
  Checkbox,
  Collapse,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { groupReasonForLookups } from "../../utils/CommonUtils";
import { NestedMenuItem } from "mui-nested-menu";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LOOKUP_MENUS } from "../../mock/menumock";
// Define a custom MenuItem component
const CustomMenuItem = styled(MenuItem)(({ theme, isSelected }) => ({
  position: "relative",
  backgroundColor: "transparent", // Default background
  // backgroundColor: isSelected ? theme.palette.grey[300] : 'transparent',
  "&:hover": {
    backgroundColor: theme.palette.mode === "light" && theme.palette.grey[200],
  },
  "&:focus": {
    backgroundColor: theme.palette.mode === "light" && theme.palette.grey[300],
    "&:hover": {
      backgroundColor:
        theme.palette.mode === "light" && theme.palette.grey[200],
    },
  },
  "&:focusVisible, &:focusWithin, &:active": {
    backgroundColor: theme.palette.mode === "light" && theme.palette.grey[200], // Custom color when clicked
  },
  // Ensures no default styling is applied during click
  "&.Mui-focusVisible, &.Mui-selected, &.Mui-focusVisible.Mui-selected": {
    backgroundColor: theme.palette.mode === "light" && theme.palette.grey[300],
    outline: "none",
    boxShadow: "none",
  },

  // Disable transition effects that might cause delay
  transition: "background-color 0s",
  // Add a divider-like border after the last child using a pseudo-element
  "&::after": {
    content: '""',
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "100%",
    height: "1px",
    backgroundColor: theme.palette.divider,
  },
}));

const CustomSelect = styled(Select)(({ theme }) => ({
  minWidth: 170, // Prevent width change
  maxWidth: 170,
  minHeight: 40,
  maxHeight: 40,
  border: "none",
  background: "none",
  boxShadow: "none",
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "&.MuiInputBase-root": {
    background: "none",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "& .MuiSelect-select": {
    background: "none",
  },
}));

const ACTDropdownWithSubMenuList = (props) => {
  const { options, title, onRefreshData, type, selectedItem, name } = props;
  const [selectedItemId, setSelectedItemId] = useState(
    selectedItem ? selectedItem.id : options[0].id
  );
  const theme = useTheme();
  const data = groupReasonForLookups(options);
  const allkeys = Object.keys(data);
  const [open, setOpen] = useState(false);

  const handleChange = (event) => {
    if (onRefreshData) {
      const selectedItem = options.find(
        (item) => item.id === event.target.value
      );
      onRefreshData(selectedItem, type, name);
    }
    setSelectedItemId(event.target.value);
    // setOpen(false);
  };

  const handleClose = () => {
    // setOpen(!open);
  }

  const handleClick = (event) => {
    return setOpen(true);
  };


  return (
    <CustomSelect
      placeholder={title}
      onChange={handleChange}
      value={selectedItemId}
      // open={open}
      // onClick={handleClick}
      // onClose={handleClose}
      // value={selectedItemId ? selectedItemId : ""}
    >
      {allkeys.map((item) => {
        const value = data[item];
        if (value?.subGroups) {
          return (
            <NestedMenuItem key={value.item.id} value={value.item.id} label={item} parentMenuOpen={true}>
              {value?.subGroups.map((subItem) => {
                if (subItem.items.length === 1) {
                  const subItemValue = subItem.items[0]
                  return (
                    <CustomMenuItem key={subItemValue.id} value={subItemValue.id}>
                      {/* <Checkbox /> */}
                      <Typography
                        sx={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          paddingRight: "30px",
                          color: theme.palette.text.primary,
                        }}
                      >
                        {subItem.name}
                      </Typography>
                    </CustomMenuItem>
                  );
                } else {
                  return (
                    <NestedMenuItem label={subItem.name} parentMenuOpen={true}>
                      {subItem.items.map((innerItem) => {
                        return (
                          <CustomMenuItem
                            key={innerItem.id}
                            value={innerItem.id}
                          >
                            {/* <Checkbox /> */}
                            <Typography
                              sx={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                paddingRight: "30px",
                                color: theme.palette.text.primary,
                              }}
                            >
                              {innerItem.FriendlyName}
                            </Typography>
                          </CustomMenuItem>
                        );
                      })}
                    </NestedMenuItem>
                  );
                }
              })}
            </NestedMenuItem>
          );
        } else {
          return (
            <CustomMenuItem key={value.id} value={value.id}>
              {/* <Checkbox /> */}
              <Typography
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  paddingRight: "30px",
                  color: theme.palette.text.primary,
                }}
              >
                {item}
              </Typography>
            </CustomMenuItem>
          );
        }
      })}
    </CustomSelect>
  );
};

export default ACTDropdownWithSubMenuList;
