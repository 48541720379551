import React, { useState } from "react";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Popover from "@mui/material/Popover";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ACTPieChart from "./ACTPieChart";
import CircularProgress from "@mui/material/CircularProgress";
import ACTLegends from "./ACTLegends";

const ACTPieCard = (props) => {
  const { data, title, link, activityHandler } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const onClickAction = () => {
    if (link && activityHandler) {
      activityHandler();
    }
  };
  const getTop3Item = (data) => {
    const top3 = data.results.slice(0, 3);
    const more = { id: -1 };
    return [...top3, more];
  };
  return (
    <Card>
      <CardHeader
        // action={
        //   <IconButton aria-label="settings">
        //     <MoreVertIcon />
        //   </IconButton>
        // }
        title={
          <Typography
            variant="body1"
            component="div"
            sx={{
              fontSize: "1.2rem",
              fontWeight: "500",
              textDecoration: link ? "underline" : "none",
              cursor: link ? "pointer" : "text",
            }}
            onClick={onClickAction}
          >
            {title}
          </Typography>
        }
      />
      <Divider />
      <CardContent>
        {!data && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <CircularProgress size={30} color="secondary" />
          </div>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // height: "100%",
            minHeight: "200px",
            maxHeight: "350px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
            }}
          >
            {data && !data.results && <>Failed to load</>}
            {data && data.results && <ACTPieChart data={data.results} />}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            {data && data.results && (
              <ACTLegends
                data={
                  data.results.length > 3 ? getTop3Item(data) : data.results
                }
                handleClick={handleClick}
              />
            )}
          </div>
        </div>
      </CardContent>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <div style={{margin: '1rem'}}>
          <ACTLegends data={data.results} />
        </div>
      </Popover>
    </Card>
  );
};
export default ACTPieCard;
