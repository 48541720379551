import React, { useState } from "react";
import { styled } from "@mui/system";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {
  Collapse,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { groupReasonForLookups } from "../../utils/CommonUtils";
import { NestedMenuItem } from "mui-nested-menu";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LOOKUP_MENUS } from "../../mock/menumock";
// Define a custom MenuItem component
const CustomMenuItem = styled(MenuItem)(({ theme, isSelected }) => ({
  position: "relative",
  backgroundColor: "transparent", // Default background
  // backgroundColor: isSelected ? theme.palette.grey[300] : 'transparent',
  "&:hover": {
    backgroundColor: theme.palette.mode === "light" && theme.palette.grey[200],
  },
  "&:focus": {
    backgroundColor: theme.palette.mode === "light" && theme.palette.grey[300],
    "&:hover": {
      backgroundColor:
        theme.palette.mode === "light" && theme.palette.grey[200],
    },
  },
  "&:focusVisible, &:focusWithin, &:active": {
    backgroundColor: theme.palette.mode === "light" && theme.palette.grey[200], // Custom color when clicked
  },
  // Ensures no default styling is applied during click
  "&.Mui-focusVisible, &.Mui-selected, &.Mui-focusVisible.Mui-selected": {
    backgroundColor: theme.palette.mode === "light" && theme.palette.grey[300],
    outline: "none",
    boxShadow: "none",
  },

  // Disable transition effects that might cause delay
  transition: "background-color 0s",
  // Add a divider-like border after the last child using a pseudo-element
  "&::after": {
    content: '""',
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "100%",
    height: "1px",
    backgroundColor: theme.palette.divider,
  },
}));

const CustomSelect = styled(Select)(({ theme }) => ({
  minWidth: 170, // Prevent width change
  maxWidth: 170,
  minHeight: 40,
  maxHeight: 40,
  border: "none",
  background: "none",
  boxShadow: "none",
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "&.MuiInputBase-root": {
    background: "none",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "& .MuiSelect-select": {
    background: "none",
  },
}));

const ACTDropdownWithCollapseMenuList = (props) => {
  const { options, title, onRefreshData, type, selectedItem, name } = props;
  const [selectedItemId, setSelectedItemId] = useState(
    selectedItem ? selectedItem.id : options[0].id
  );
  const theme = useTheme();

  const [open, setOpen] = useState(false);

  const handleChange = (event) => {
    if (onRefreshData) {
      const selectedItem = options.find(
        (item) => item.id === event.target.value
      );
      onRefreshData(selectedItem, type, name);
    }
    setSelectedItemId(event.target.value);
    setOpen(false);
  };

  const handleClose = () => {
    // setOpen(!open);
  }

  const handleClick = (event) => {
    setOpen(true);
  };

  function hasChildren(item) {
    const { items: children } = item;

    if (children === undefined) {
      return false;
    }

    if (children.constructor !== Array) {
      return false;
    }

    if (children.length === 0) {
      return false;
    }

    return true;
  }

  const SingleLevel = ({ item }) => {
    return (
      <ListItem button>
        <ListItemText primary={item.title} />
      </ListItem>
    );
  };

  const MultiLevel = ({ item }) => {
    const { items: children } = item;
    const [open, setOpen] = useState(false);

    const handleClick = () => {
      // setOpen((prev) => !prev);
      setOpen(!open)
    };

    return (
      <React.Fragment>
        <ListItem button onClick={handleClick}>
          <ListItemText primary={item.title} />
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((child, key) => (
              <MyMenuItem key={key} item={child} />
            ))}
          </List>
        </Collapse>
      </React.Fragment>
    );
  };

  const MyMenuItem = ({ item }) => {
    const Component = hasChildren(item) ? MultiLevel : SingleLevel;
    return <Component item={item} />;
  };

  return (
    <CustomSelect
      placeholder={title}
      onChange={handleChange}
      value={selectedItemId}
      open={open}
      // onClick={handleClick}
      // onClose={handleClose}
      // value={selectedItemId ? selectedItemId : ""}
    >
      {LOOKUP_MENUS.map((item, index) => {
        return <MyMenuItem key={index} item={item} />;
      })}
    </CustomSelect>
  );
};

export default ACTDropdownWithCollapseMenuList;
